import './BottomPanel.css'




function BottomPanel() {
    return (
        <div className='bottompanel_body'>
            <div className='bottompanel_texts'>
                <div>
                    <div className='bottompanel_text'>Наши контакты</div>
                    <div className='bottompanel_text'>+7 (987) 235-93-55</div>
                    <div className='bottompanel_text'>press@mediarent.tech</div>
                    
                </div>
                <div>
                    <div className='bottompanel_text'>ООО «МедиаРент»
                        Юридический Адрес 420033, Рес. Татарстан, г. Казань, ул. Фрунзе, дом 1, помещение 34.
                        ОГРН 124160006460
                        ИНН 1683019197</div>
                </div>
                
                
            </div>
        </div>
    )
}
export default BottomPanel;