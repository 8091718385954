import './CarouselImg.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import img8 from "../../images/img8.png"
function CarouselImg() {
    return (
        <div className='carousel_img_body'> 
            <h1 className='carousel_img_header'>Наши проекты</h1>
            
            
            <Carousel>
                
                <Carousel.Item>
                    <img src={img8} className="d-block w-100"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img8} className="d-block w-100" />
                </Carousel.Item>
                <Carousel.Item>
                    <img src={img8} className="d-block w-100" />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}
export default CarouselImg;