import './WhyOur.css'

import img6 from "../../images/img6.jpg"



function WhyOur() {
    return (
        <div className='whiour_body'>
            <div className='whiour_row'>
                <div class="whiour_list">
                    <h1 className='whiour_list_header'>Почему именно мы?</h1>
                    <div className='whiour_list_item'>Сегодня мы готовы взять на себя ответственность
                        по техническому сопровождению любого вашего
                        мероприятия, от А до Я.
                        Логичный вопрос, чем вы лучше других?
                        Мы готовы ответить на этот вопрос!
                        Как было сказано выше, мы сами занимались
                        монтажом и техническим сопровождением
                        мероприятий в рамках других медиа компаний,
                        занимались поиском технических решений, для
                        максимального удобства наших клиентов.
                        Сегодня мы готовы брать на себя ответственность
                        и реализовывать ваши самые смелые фантазии,
                        максимально воплощать ваши визуализации в
                        жизнь
                        MediaRent
                        -ваша фантазия
                        -наша реальность</div>
                </div>
            </div>
        </div>
    )
}
export default WhyOur;