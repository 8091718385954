import './FormInput.css'
import React, { useState } from "react";
function FormInput() {
    
    
    const [checked, setChecked] = useState(false);

    return (
        <div className='forminput_body' >
            <h1 className='form_heder'>Уточнить более подробную информацию</h1>
            <form action="https://api.web3forms.com/submit" method="POST">
                <input type="hidden" name="access_key" value="e3c62c51-3a13-4133-8a1f-02f67303980a"></input>
                <div>Ваше имя</div>
                <input className='forminput_input' type="name" name='name'></input>
                <div>Ваше e-mail</div>
                <input className='forminput_input' type="email" name='email'></input>
                <div>Ваш телефон</div>
                <input className='forminput_input' type="phone" name='phone'></input>
                <div></div>
                <button className='forminput_button' disabled={!checked}>Отправить</button>
            </form>
            
            
            <div className='form_block_chek'> <input id='politics' type='checkbox' className='form_check' onChange={e => setChecked(e.target.checked)} /> Я даю своё согласие на обработку персональных данных, принимаю политику <a href='https://cloud.mail.ru/public/sJpp/U6Dcrfnwf'>конфиденциальности</a></div>
        </div>
    )
}
export default FormInput;