import { Container, Navbar, Nav, Image, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'
import logo from "../../images/logo1.png"


function Header ()
{
    return(
        <Navbar bg="dark" data-bs-theme="dark" className='header_body' >
            <Container>
                <Navbar.Brand href="#home" className='header_band_text'>
                    <img className='header_logo' src={logo}></img>
                    MEDIARENT
                </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href='#about-our' className='header_text'>О нас</Nav.Link>
                    <Nav.Link href="#our-sell" className='header_text'>Наши услуги</Nav.Link>
                    <Nav.Link href="#our-profil" className='header_text'>Наш профиль</Nav.Link>
                    <Nav.Link href="#our-team" className='header_text'>Наша команда</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}
export default Header;