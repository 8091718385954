import './About.css'

import img5 from "../../images/img5.jpg"



function About() {
    return (
        <div className='about_body' id="about-our">
            <div className='about_row'>
                <div class="about_list">
                    <h1 className='about_list_header'>Немного о нас</h1>
                    <div className='about_list_item'>MediaRent-это молодой, динамично
                        развивающийся медиа прокат!
                        <br/>
                        Наша команда прошла путь с самых низов,
                        мы занимались монтажом и техническим
                        сопровождением разного рода проектов!</div>
                </div>

                
                



            </div>
        </div>
    )
}
export default About;