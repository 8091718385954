import './WhatSel.css'
import img4 from "../../images/img4.jpg"




function WhatSel() {
    return (
        <div className='whatsel_body' id="our-sell">
            <div className='whatsel_row'>
                
                <div class="whatsel_list">
                    <h1 className='whatsel_list_header'>Что мы можем предложить</h1>
                    <h1 className='whatsel_list_header'>Аренда и организация Вашего мероприятия</h1>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />Светодиодный экран с шагом 2.6 мм и 3.8</div>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />Сенсорные панель</div>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />LED и LSD телевизоры</div>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />Звуковое оборудование</div>
                    <h1 className='whatsel_list_header'>Стационарная установка оборудования</h1>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />Светодиодный экран</div>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />Сенсорные панель</div>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />LED и LSD телевизоры</div>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />Интерактивные доски</div>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />Проекторы</div>
                    <div className='whatsel_list_item'><img className='whatsel_point' src={img4} />Звуковое оборудование</div>
                    <div className='whatsel_list_item'> <a href='https://cloud.mail.ru/public/XEr9/nZQPWFe8D' style={{ color: "black" }}> Здесь вы можете ознакомиться с готовыми решениями для вашего мероприятия</a></div>

                </div>

                




            </div>
        </div>
    )
}
export default WhatSel;