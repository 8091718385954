import './OurProfil.css'
import img3 from "../../images/img3.jpg"
import img4 from "../../images/img4.jpg"



function OurProfil() {
    return (
        <div className='ourprofil_body' id="our-profil">
            <div className='ourprofil_row'>                
                
                
                <div class="ourprofil_list">
                    <h1 className='ourprofil_list_header'>Наш профиль</h1>
                    <div className='ourprofil_list_item'><img className='ourprofil_point' src={img4} />Техническое сопровождение мероприятия</div>                  
                    <div className='ourprofil_list_item'><img className='ourprofil_point' src={img4} />Аренда мультимедийного оборудования</div>
                    <div className='ourprofil_list_item'><img className='ourprofil_point' src={img4} />Стационарная установка мультимедийного оборудования</div>
                    <div className='ourprofil_list_item'><img className='ourprofil_point' src={img4} />Звуковое сопровождение вашего мероприятия</div>
                </div>
                

                
            </div>
        </div>
    )
}
export default OurProfil;