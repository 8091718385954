import './Events.css'


import img9 from "../../images/img9.png"
import img10 from "../../images/img10.png"
import img11 from "../../images/img11.png"
import img12 from "../../images/img12.png"



function Events() {
    return (
        <div className='events_body'>
            <div className='events_heder'>Мероприятия где мы работаем</div>
            <div className='events_list'>
                <div><img className='events_imgs' src={img9} /><div>Выставки</div></div>
                <div><img className='events_imgs' src={img10} /><div>Праздники</div></div>
                <div><img className='events_imgs' src={img11} /><div>Конференции</div></div>
                <div><img className='events_imgs' src={img12} /><div>Форумы</div></div>
            </div>
            
        </div>
    )
}
export default Events;