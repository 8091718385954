import React from "react";
import Header from "./components/header/Header";

import 'bootstrap/dist/css/bootstrap.min.css';
import OurTeam from "./components/our_team/OurTeam";
import OurProfil from "./components/out_profil/OurProfil";
import About from "./components/about/About";
import WhyOur from "./components/why_our/WhyOur";
import WhatSel from "./components/what_sel/WhatSel"
import Events from "./components/events/Events";
import './App.css'
import FormInput from "./components/form_input/FormInput";
import BottomPanel from "./components/bottom_panel/BottomPanel";
import CarouselImg from "./components/carousel_img/CarouselImg";





function App() {
  return (
    <div className="App">
      <Header></Header>
      
      <div className="app_row0">
        <About />
        
        
      </div>

      <div className="app_row1">
        <div className="app_block">          
          <Events />
        </div>

        <div className="app_block">
          <OurProfil />
        </div>

      </div>

      <div className="app_row2">
        <div className="app_block">
          <WhatSel />
        </div>
        
        <div className="app_block">
          <FormInput />
        </div>
        
        
      </div>
      
      <div className="app_row1">
        <div className="app_block">
          <OurTeam /> 
        </div>

        <div className="app_block">
          <WhyOur />
        </div>

      </div>

      
       
      
      <CarouselImg/>
      <BottomPanel/>
      
    </div>
  );
}

export default App;
