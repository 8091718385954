import './OurTeam.css'
import icon1 from "../../images/img1.jpg"
import icon2 from "../../images/img2.jpg"


function OurTeam() {
    return (
        <div className='ourteam_body' id="our-team">
            <h1 className='ourteam_header'>Наша команда</h1>
            <div style={{ textAlign: "center" }}>
                <img className='ourteam_image' src={icon1} />
                <div className='ourteam_text'>Салахов Эльвар Ильмарович</div>
                <div className='ourteam_text'>Директор</div>
                <div className='ourteam_text'>+7-932-888-10-88</div>
            </div>
            <div style={{ textAlign: "center" }}>
                <img className='ourteam_image' src={icon2} />
                <div className='ourteam_text'>Хрулев Евгений Эдуардович</div>
                <div className='ourteam_text'>Директор по развитию</div>
                <div className='ourteam_text'>+7-987-235-93-55</div>
            </div>
            
        </div>
    )
}
export default OurTeam;
